@import './assets/css/variables';

*:focus {
    outline: 0;
}

body {
    background-color: white;
    font-family: 'GothamNarrowBook', Arial, sans-serif;
}

.layout-39 {
    width: 100%;
    min-width: 1350px;
    background-color: white;
    margin: 0 auto;
    overflow: hidden;

    .inline-block {
        display: inline-block;
    }
}

@mixin area-limited-centered {
    max-width: 1320px;
    margin: 0 auto;
    display: block;
}

.main {
    width: 100%;
    max-width: 100%;
    min-width: 1350px;
    padding: 0 15px;

    .header-container {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        margin: 30px 0 20px 0;

        .header-title {
            font-size: 26px;
            font-weight: 600;
            color: $sctcolorlight;
        }

        .header-buttons {
            display: flex;
            flex-direction: row;
        }
    }

    .area-centered {
        @include area-limited-centered;

        &.mat-tab-header {
            display: flex;
        }
    }

    .filter-container {
        @include area-limited-centered;
    }

    .buttons-container {
        @include area-limited-centered;
    }

    .chart-container {
        @include area-limited-centered;
    }

    .tab-nav-px15 {
        padding: 0 15px;
    }
}

.filter-panel-container {
    mat-expansion-panel-header.mat-expansion-panel-header {
        padding: 8px 30px !important;
    }

    .filter-panel-header-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        min-height: 48px;

        .filter-panel-header-content {
            display: flex;
            flex-direction: row;
            flex: 1;
            align-items: baseline;

            .filter-by-button {
                font-family: 'GothamBookMedium', Arial, sans-serif;
                font-size: 18px;
                color: $sctcolor;
                display: flex;
                align-items: center;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    margin-right: 10px;
                }
            }
        }

        .filter-panel-header-buttons {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;

            button {
                outline: none !important;
                font-size: 12px;

                &.light-blue {
                    color: $sctcolorlight;
                }
            }
        }
    }

    .filter-panel-content-container {
        .filters-row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-end;

            &.col1 {
                width: 25%;
            }
            &.col2 {
                width: 50%;
            }
            &.col3 {
                width: 75%;
            }
            &.col4 {
                width: 100%;
            }

            .filter-item {
                flex-grow: 1;
                margin: 0 5px;

                mat-form-field.mat-form-field {
                    width: 100%;
                }
            }
        }
    }
}

.results-panel {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    min-height: 300px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    padding: 30px;
    margin-bottom: 20px;

    .results-panel-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .results-panel-header-container {
            display: flex;
            flex-direction: column;
            flex: 1;

            .results-panel-header-title-row {
                display: flex;
                flex-direction: row;
                flex: 1;
                justify-content: space-between;
                margin-bottom: 10px;
            }
        }

        .results-panel-content-container {
            display: flex;
            flex-direction: column;
            flex: 1;
        }
    }
}

.mat-menu-panel.columns-customizer {
    background-color: $sctcolorgreylight;
    max-width: 350px;

    .columns-customizer-list {
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-height: 200px;
        min-width: 250px;

        mat-checkbox {
            font-size: 14px;
            border-bottom: 1px solid $sctcolorgreymid;

            &:last-child {
                border-bottom: none;
            }

            label {
                margin: 5px 0;
            }
        }
    }
}

.results-panel-header-container {
    .results-header-actions {
        .results-columns-customizer {
            color: $sctcolorlight;
            outline: none !important;

            .mat-icon {
                color: #00adee;
                border-radius: 50%;
                width: 32px;
                height: 32px;
            }
        }

        button.results-clear-flags {
            color: #979797;
            mat-icon {
                font-size: 16px;
                background-color: #ededed80;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                padding: 8px;
            }
        }

        .results-header-toggle {
            font-size: 14px;
        }
    }
}

.results-title {
    .results-header-actions {
        .results-columns-customizer {
            color: $sctcolorlight;
            outline: none !important;

            .mat-icon {
                color: #00adee;
                border-radius: 50%;
                width: 32px;
                height: 32px;
            }
        }

        button.results-clear-flags {
            color: #979797;
            mat-icon {
                font-size: 16px;
                background-color: #ededed80;
                border-radius: 50%;
                width: 32px;
                height: 32px;
                padding: 8px;
            }
        }

        .results-header-toggle {
            font-size: 14px;
        }
    }
}
.results-panel-content-container {
    .results-info {
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $sctcolorgreydark;
        margin-bottom: 10px;

        .results-info-highlight {
            font-weight: 600;
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $sctcolorlight;
            margin-right: 5px;

            mat-icon {
                font-size: 16px;
                height: 16px;
                width: 16px;
                margin-right: 10px;
            }
        }
    }

    .table-container {
        table {
            th {
                position: relative;
                background-color: $sctcolorgreylight;
                border-top: 1px solid $sctcolorgreymid;
                border-bottom: 1px solid $sctcolorgreydark;

                &.header-filtered,
                .header-filtered {
                    position: relative;
                }

                &.header-filtered::before,
                .header-filtered::before {
                    position: absolute;
                    content: '';
                    height: 4px;
                    width: 100%;
                    left: 0;
                    top: 0;
                    background-color: $sctcolorlight;
                }
            }

            td.mat-table-sticky {
                background-color: $sctcolorgreylight;
            }

            td.row-underline {
                border-bottom: 1px solid $sctcolorgreydark;
            }

            a:not([href]):not([tabindex]) {
                color: $sctcolorlight;
                cursor: pointer;
            }
        }
    }
}

h3 {
    color: $sctcolorlight;
}

h4 {
    color: $sctcolor;
    text-align: left;
    margin: 0;
}

.multiple-items-error.mat-form-field-appearance-legacy .mat-form-field-label {
    color: red;
}

.multiple-items-error.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: red;
}

.import-subtitle {
    margin-bottom: 10px;
}

app-navbar nav#site-specific-nav {
    background: $sctcolordark;

    ul:not(.in-mega) > li:hover {
        background: $sctcolor;
    }
}

.margin {
    &-left {
        &20 {
            margin-left: 20px;
        }
    }

    &-right {
        &15 {
            margin-right: 15px;
        }

        &20 {
            margin-right: 20px;
        }

        &40 {
            margin-right: 40px;
        }
    }
}

// BUTTONS

.buttons-container.flex {
    display: flex;

    &.center {
        justify-content: center;
    }
}

.sct-button {
    background-color: $sctcolor;
    font-size: 16px;
    text-align: center;
    height: 40px;
    width: 220px;
    font-family: 'GothamBook';
    border: 1px solid white;
    color: white;
    border-radius: 1px;

    &:hover {
        background-color: $sctcolorlight;
    }

    &:focus {
        outline: 0;
    }

    &:disabled {
        background-color: rgba(68, 68, 68, 0.1);
        border: none;
        color: rgb(103, 103, 103, 0.5);
        cursor: default;
    }

    &.sct-button-light {
        background-color: $sctcolorlight;
        border-color: $sctcolorlight;

        &:hover {
            background-color: $sctcolor;
            border-color: $sctcolor;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }

    &.sct-button-white {
        background-color: white;
        border: 1px solid rgba(68, 68, 68, 0.4);
        color: #676767;

        &:hover {
            color: black;
            border-color: rgba(0, 0, 0, 0.4);
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
            cursor: default;
        }
    }

    &.sct-button-red {
        background-color: $medium-red;
        border-color: $medium-red;

        &:hover {
            background-color: $dark-red;
            border-color: $dark-red;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }
    &.sct-button-green {
        background-color: $light-green;
        border-color: $light-green;

        &:hover {
            background-color: $medium-green;
            border-color: $medium-green;
        }

        &:disabled {
            background-color: rgba(68, 68, 68, 0.1);
            border: none;
            color: rgb(103, 103, 103, 0.5);
        }
    }
}

button {
    cursor: pointer;
}

app-detailed-status .filter-container {
    .mat-expansion-panel-body {
        display: flex;
        flex-wrap: wrap;
    }
}

app-redzone .filter-container,
app-bluezone .filter-container,
app-change-management .filter-container {
    .mat-expansion-panel-body {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
}

//Accordion
mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
    padding: 8px 18px 8px 24px;
}

.mat-expansion-indicator::after {
    color: $sctcolorlight;
}

.mat-expansion-panel-header {
    h3 {
        margin-bottom: 0;
    }

    .mat-chip.mat-standard-chip {
        background-color: #cceffc;
    }

    .mat-standard-chip {
        font-size: 11px;
        color: #00adef;
        border: 1px solid #0db1ef;
        border-radius: 3px;
        min-height: 24px;
    }

    .mat-basic-chip {
        display: inline-flex;
        font-size: 11px;
        border: 1px solid #0db1ef;
        padding: 0 8px 0 4px;
        border-radius: 3px;
        color: #00adef;
        background-color: #cceffc;
        align-items: center;
        cursor: default;
        min-height: 24px;
    }

    .mat-chip .mat-chip-trailing-icon.mat-icon,
    .mat-chip .mat-chip-remove.mat-icon {
        font-size: 18px;
        height: 18px;
        width: 18px;
        margin-left: 4px;
        color: rgba(0, 0, 0, 0.87);
    }

    .mat-chip-list-wrapper .mat-basic-chip,
    .mat-chip-list-wrapper input.mat-input-element {
        margin: 4px;
    }

    .mat-chip.mat-basic-chip .mat-chip-remove {
        opacity: 0.4;
        cursor: pointer;
    }

    .mat-expansion-indicator {
        margin: 0 4px;
    }
}

.results-container {
    .title-date {
        font-family: 'GothamNarrowBold';
        font-size: 20px;
        text-transform: lowercase;
        color: $sctcolor;
    }
    .results-title {
        margin-bottom: 20px;
    }
}

//TABLES

th.mat-header-cell[class*='mat-column-'] {
    font-size: 11.5px;
    color: #222222;
}

td.mat-cell[class*='mat-column-'] {
    font-size: 12.5px;
}

general-app-configuration,
.results-container,
.upload-log-container,
.fieldchange-container,
.delta-container,
.planning-container,
.weekly-commitments-container,
.daily-container,
.comments-container,
.top-header-section-container {
    td.mat-cell,
    th.mat-header-cell {
        padding: 0 3px;

        &:first-of-type {
            padding-left: 16px;
        }

        &:last-of-type {
            padding-right: 16px;
        }
    }

    th.mat-header-cell {
        padding: 0 2px;
    }

    table tbody tr:nth-child(odd) td {
        background: #ffffff;
    }

    //selcted row hightlight
    table tbody tr {
        &.highlight td,
        &.highlight td.mat-table-sticky,
        &.highlight:nth-child(odd) td,
        &.highlight:hover td,
        &.highlight:hover td.mat-table-sticky,
        &.highlight:hover:nth-child(odd) td {
            background: #c8f0ff;
        }
    }

    .mat-header-cell {
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        color: #222222;
    }

    th.mat-header-cell.mat-column-mcForecast .mat-sort-header-container {
        justify-content: center;
    }
}

.planning-container,
.weekly-commitments-container,
.daily-container,
.result-table,
.comments-section {
    table tbody tr:nth-child(odd) td {
        background: #ffffff;

        &.mat-column-actions {
            background: #fbfbfb;
        }
    }
}

//table row highlight
app-redzone,
app-bluezone,
app-schedule-activity-planning-subsystem-table,
app-rfo {
    .results-container {
        table tbody tr:hover td,
        table tbody tr:hover td.mat-table-sticky,
        table tbody tr:nth-child(odd):hover td {
            background: #f0faff;
        }
    }
}

//upload log table

.upload-log-container {
    th.mat-header-cell.mat-column {
        &-type,
        &-status,
        &-startDate,
        &-endDate {
            width: 90px;
        }

        &-infoMessage {
            min-width: 340px;
        }

        &-errorMessage {
            min-width: 100px;
        }

        &-user {
            width: 108px;
        }

        &-downloadResult {
            width: 86px;
        }
    }

    td.mat-cell.mat-column {
        &-errorMessage {
            word-break: break-all;
            word-break: break-word;
        }
    }

    th.mat-column-downloadResult,
    td.mat-column-downloadResult {
        text-align: center;
    }
}

//Change-to-subsys maintenance table
app-subsystem-conflicts {
    .results-container th.mat-header-cell:last-of-type,
    .results-container td.mat-cell:last-of-type {
        padding-right: 6px;
    }

    td.mat-cell app-multiple-items-selector .mat-form-field-infix {
        width: 250px;
    }
}

th.mat-header-cell {
    border-bottom: 1px solid #808080;
    border-top: 1px solid #c5c5c5;
    background: #fbfbfb;
}

//Changes for angular materials
.filter-container {
    input.mat-input-element,
    mat-select.mat-select {
        font-weight: 600;
    }
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: $sctcolorlight;
}

.signoff-container .mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #9e9e9e;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: $sctcolor;
}

.signoff-container .mat-form-field.mat-focused .mat-form-field-label {
    color: #9e9e9e;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $sctcolor;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: $sctcolorlight;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
    background: $sctcolorlight;
}

.filter-container .container610 mat-form-field {
    width: 610px;
    max-width: 610px;
}

.filter-container .container620 mat-form-field {
    width: 620px;
    max-width: 620px;
}

.filter-container .container480 mat-form-field {
    width: 480px;
    max-width: 480px;
}

.filter-container .container300 mat-form-field,
.filter-container .container300-mh55 mat-form-field {
    width: 300px;
    max-width: 300px;
}

.filter-container .container250 mat-form-field,
.filter-container .container250-mh55 mat-form-field {
    width: 250px;
    max-width: 250px;
}

.filter-container .container350 mat-form-field {
    width: 350px;
}

.filter-container .mixedapp-container .container265 .mat-form-field-infix {
    width: 265px;
}

.filter-container .mixedapp-container .container300 .mat-form-field-infix {
    width: 300px;
}

.filter-container .mixedapp-container .container620 .mat-form-field-infix {
    width: 620px;
}

.filter-container .mixedapp-container .container480 .mat-form-field-infix {
    width: 480px;
}

app-subsystem-selector app-multiple-items-selector {
    &.hidesearchph .mat-input-element::placeholder {
        color: white;
    }

    .mat-form-field-infix {
        border-top: 0;

        input {
            font-family: 'GothamNarrowBook', Arial, sans-serif;
            font-size: 16px;
            font-stretch: 100%;
            color: rgb(33, 37, 41);
        }
    }

    .mat-form-field-appearance-outline {
        .mat-form-field-infix {
            padding: 12px 0;
        }

        .mat-form-field-outline {
            top: 0;
        }

        .mat-form-field-flex {
            padding: 0px 9px;
        }

        .mat-form-field-wrapper {
            margin: 3px 0;
        }

        &.mat-focused .mat-form-field-outline {
            transition: none;

            &-thick {
                color: #dbdcdd;
            }
        }

        &:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
            color: transparent;
            border: 1px solid #dbdcdd;
            border-radius: 5px;
        }
    }
}

.filter-container .mixedapp-container .container940 .mat-form-field-infix {
    width: 940px;
}

.mat-column-mcForecast .mat-button-focus-overlay {
    background: none;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $sctcolorlight;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color: $sctcolorlight;
}

//checkboxes
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
    background-color: $sctcolorlight;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
    background-color: $sctcolorlight;
}

//table columns checkboxes
.table-checkboxes {
    .mat-checkbox {
        margin-right: 26px;

        &:last-child {
            margin-right: 0px;
        }

        label {
            margin-bottom: 0;
        }
    }

    .mat-checkbox-label {
        font-family: 'GothamNarrowBold', Arial, sans-serif;
        font-size: 12px;
        color: #222222;
    }
}

//acordion expansion panel for Detailed Status
app-detailed-status .mat-expansion-panel-body {
    width: 1320px;
    padding: 0 10px 16px 30px;
}

app-detailed-status .filter-container .multiselector {
    flex: 1 1 50%;

    mat-form-field {
        width: 100%;
    }
}

.mat-autocomplete-panel tr:not(.tr-selected) {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex {
    cursor: pointer;
}

app-multiple-items-selector .mat-form-field-flex input {
    cursor: pointer;
}

tr.tr-selected td {
    color: #b2b2b2;
}

.mat-autocomplete-panel h4 {
    padding-top: 10px;
}

.mat-chip-list {
    width: 100%;
}

.mat-chip-list input.mat-chip-input {
    width: 100%;
}

_:-ms-fullscreen,
:root .mat-chip-list-wrapper {
    display: block;
}

.filter-loader,
.delta-loader,
.upload-log-loader,
.table-complete-loader,
.schedule-loader,
.table-outstanding-loader,
.weekly-validation-progress-loader,
.validation-progress-loader,
.cumulative-validation-progress-loader,
.monthly-change-raised-loader,
.validator-performance-scoreboard-loader,
.systemisation-progress-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
    z-index: 1000;
}

.lds-hourglass:after {
    content: ' ';
    display: block;
    border-radius: 50%;
    box-sizing: border-box;
    border: 25px solid #0066b2;
    border-color: #0066b2 transparent #0066b2 transparent;
    animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
    0% {
        transform: rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    50% {
        transform: rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }

    100% {
        transform: rotate(1800deg);
    }
}

.mat-expansion-panel {
    position: relative;
}

//Admin-change-document tabs
//width of the tabs in Admin Panel mat-tab-links
app-change-document .main .mat-tab-label {
    min-width: 165px;
}

app-automatic-upload,
app-data-maintenance,
app-weekly-planning-session,
app-reports-skyline-plan,
app-reports-planning-lookahead,
app-reports-planning-commitment-reliability {
    .main .mat-tab-link {
        min-width: 180px;
    }
}

app-manual-upload {
    .main .mat-tab-link {
        min-width: 172px;
    }
}

//Remove scroll when swithing around tabs in Admin Panel import container
app-change-document .main .import-container .mat-tab-body {
    &-content {
        overflow-y: hidden;
    }

    &.mat-tab-body-active {
        overflow-y: hidden;
    }
}

.main .mat-tab-group.mat-primary .mat-ink-bar,
.main .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: $sctcolorlight;
}

a.mat-tab-link {
    &:visited,
    &:hover {
        border-bottom: none;
        color: rgba(0, 0, 0, 0.87);
    }
}

.main .mat-tab-link:not(.mat-tab-label-active) {
    background-color: rgba(216, 216, 216, 0.4);
}

//Comments buttons text position
button.add-comment.mat-stroked-button {
    line-height: 29px;
}

app-change-document,
app-automatic-upload,
app-manual-upload,
app-planning-upload {
    .in-progress {
        padding-right: 20px;
    }
}

//Admin panel tabs import progress indicator
app-change-document,
app-automatic-upload,
app-manual-upload,
app-planning-upload {
    .in-progress::after {
        content: ' ';
        position: relative;
        top: 4px;
        left: 10px;
        display: inline-block;
        border-radius: 50%;
        width: 0;
        height: 0;
        box-sizing: border-box;
        border: 10px solid $sctcolor;
        border-color: $sctcolor transparent $sctcolor transparent;
        animation: lds-hourglass 1.2s infinite;
    }
}

//changed document mat-tab
.mat-tab-header {
    border-bottom: none;
}

.mat-tab-body {
    padding: 30px;

    &-wrapper {
        background-color: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
}

.mat-tab-label {
    background-color: rgba(216, 216, 216, 0.4);

    &-active {
        color: black;
        background-color: white;
    }
}

//Change Management table
.alignleft .mat-sort-header-button {
    text-align: left;
}

//Table header filters
.mat-calendar-body-selected {
    background-color: $sctcolorlight;
}

app-asc-desc-order .sort-button.mat-icon-button {
    line-height: 24px;
}

app-header-checklist-filter .button-container {
    margin-bottom: 10px;
}

app-header-checklist-filter {
    .mat-list-base .mat-list-option .mat-list-item-content {
        height: 32px;
        padding: 0 2px;
    }

    .mat-list-base .mat-list-option.mat-list-option .mat-list-item-content .mat-list-text {
        padding-left: 8px;
    }

    .mat-accent .mat-pseudo-checkbox-checked {
        background: $sctcolorlight;
    }
}

.started-status,
.finished-status,
.error-status,
.info-status,
.warning-status {
    font-family: 'GothamBookMedium';
    width: 80px;
    text-align: center;
    border-radius: 2px;
    font-size: 11px;
}

.started-status {
    color: #ab7b03;
    border: 1px solid #ffb600;
    background: rgba(255, 182, 0, 0.2);
}

.finished-status {
    color: #80941d;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

.error-status {
    color: #bd305b;
    border: 1px solid #bd305b;
    background: rgba(189, 48, 91, 0.2);
}

.info-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}
.warning-status {
    color: #787908;
    border: 1px solid #d3da6a;
    background: rgba(232, 247, 6, 0.5);
}

.warning-status {
    color: blueviolet;
    border: 1px solid blueviolet;
    background: lavender;
}

.downloadIcon {
    cursor: pointer;
}

.regular-container {
    width: 1320px;
    padding: 40px 30px 30px 30px;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.jodit-container {
    position: relative;
    border: 1px solid black;
    padding: 5px;
    cursor: pointer;
    min-height: 150px;

    &.description-disabled {
        pointer-events: none;
        border: 1px solid gray;
        background-color: rgb(235, 235, 228);
    }
}

.page-counter {
    button.mat-paginator-navigation-next.mat-icon-button {
        margin-left: 58px;
    }

    &.paginator {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: rgba(0, 0, 0, 0.54);
        padding: 0 8px;
        position: relative;

        input {
            font-family: Roboto, 'Helvetica Neue', sans-serif;
            font-size: 12px;
            text-align: center;
            margin-left: 8px;
            border: none;
            outline: none;
            width: 50px;
            color: rgba(0, 0, 0, 0.54);
            position: absolute;
            right: 100px;
            cursor: text;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
}

//Validation status

.validation-status {
    font-family: 'GothamBookMedium';
    width: 100px;
    text-align: center;
    line-height: 20px;
    padding: 2px 0;
    border-radius: 2px;
    font-size: 11px;
}

.validation-status.completed-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}
.validation-status.testPackReview-status {
    color: #ec7318;
    border: 1px solid #ec7318;
    background: rgba(239, 106, 11, 0.2);
}
.disciplinestatus.completed-status {
    color: #00adee;
    border: 1px solid #00adee;
    background: rgba(0, 173, 238, 0.2);
}

.validation-status.changes-reviewed-status {
    color: #444b0d;
    border: 1px solid #444b0d;
    background: rgba(68, 75, 13, 0.2);
}

.validation-status.in-progress-status {
    color: #4b5216;
    border: 1px solid #4b5216;
    background: rgba(68, 75, 13, 0.4);
}
.disciplinestatus.in-progress-status {
    color: #4b5216;
    border: 1px solid #4b5216;
    background: rgba(68, 75, 13, 0.4);
}

.validation-status.not-started-status {
    color: #b2cc34;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

.validation-status.not-applicable-status {
    color: #b2cc34;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}
.disciplinestatus.not-started-status {
    color: #b2cc34;
    border: 1px solid #b2cc34;
    background: rgba(178, 204, 52, 0.2);
}

app-history-popup {
    .validation-status {
        &.completed-status {
            display: block;
            padding: 0;
        }

        &.in-progress-status {
            display: block;
            padding: 0;
        }

        &.not-started-status {
            display: block;
            padding: 0;
        }

        &.changes-reviewed-status {
            display: block;
            line-height: 1;
            padding: 2px 0;
        }
    }
}

//Chekboxes

.checkbox-section .mat-checkbox-layout {
    vertical-align: initial;
    white-space: normal;
}

.checkbox-section .mat-checkbox-layout .mat-checkbox-inner-container {
    margin: initial;
    margin-right: 8px;
}

//TABLE
//Register-Table-Reults

//Register-Table-Cursor
.results-container tbody td:first-of-type a {
    cursor: pointer;
}

.results-container .mat-cell a:hover {
    color: $linkcolor;
    border-bottom: 1px solid $linkcolor;
}

mat-tooltip-component .scttooltip {
    background-color: white;
    color: black;
    font-size: 14px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);

    &-maxwidth {
        @extend .scttooltip;
        white-space: pre-line;
        max-width: unset;
    }

    &-width400 {
        @extend .scttooltip;
        overflow: visible;
        box-shadow: 0 0 14px 12px rgb(0 0 0 / 16%);
        background-color: #fbfbfb;
        white-space: pre-line;
        max-width: 400px;
    }

    &-width800 {
        @extend .scttooltip;
        overflow: visible;
        box-shadow: 0 0 14px 12px rgb(0 0 0 / 16%);
        background-color: #fbfbfb;
        white-space: pre-line;
        max-width: 800px;
    }

    &-width1200 {
        @extend .scttooltip;
        overflow: visible;
        box-shadow: 0 0 14px 12px rgb(0 0 0 / 16%);
        background-color: #fbfbfb;
        max-width: 1200px;
    }
}

.mat-tooltip-panel-below .scttooltip-width400::after {
    content: '';
    position: absolute;
    left: calc(48% + 2px);
    bottom: 100%;
    border-width: 14px;
    border-style: solid;
    border-color: transparent transparent #fbfbfb transparent;
}

.mat-tooltip-panel-below .scttooltip-width800::after {
    content: '';
    position: absolute;
    left: calc(48% + 2px);
    bottom: 100%;
    border-width: 14px;
    border-style: solid;
    border-color: transparent transparent #fbfbfb transparent;
}

.mat-tooltip-panel-above .scttooltip-width400::before {
    content: '';
    position: absolute;
    left: calc(48% + 2px);
    top: 100%;
    border-width: 14px;
    border-style: solid;
    border-color: #fbfbfb transparent transparent transparent;
}

.mat-tooltip-panel-below .scttooltip-width1200::after {
    content: '';
    position: absolute;
    right: 285px;
    bottom: 100%;
    border-width: 14px;
    border-style: solid;
    border-color: transparent transparent #fbfbfb transparent;
}

.mat-tooltip-panel-above .scttooltip-width800::before {
    content: '';
    position: absolute;
    left: calc(48% + 2px);
    top: 100%;
    border-width: 14px;
    border-style: solid;
    border-color: #fbfbfb transparent transparent transparent;
}

.mat-select-value-text {
    font-family: 'GothamNarrowBold', Arial, sans-serif;
}

.main > .navigator .mat-icon {
    margin-right: 2px;
}

.main > a.navigator {
    cursor: pointer;
    color: $sctcolorlight;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 24px;
    margin-bottom: -8px;
}

.main > a:hover.navigator {
    color: $sctcolorlight;
    border-bottom: none;
}

/* target = Edge  BUG 1351930*/
app-data-maintenance .dmtitle-container {
    .mat-form-field {
        .mat-form-field-wrapper {
            .mat-form-field-flex {
                @supports (-ms-ime-align: auto) {
                    display: inline-block;
                }
            }
        }
    }
}

app-change-document-details textarea.mat-input-element:not([data-title='Title']) {
    font-size: 15px;
}

[app-col-resize] th {
    position: relative;

    &::before {
        cursor: col-resize;
        user-select: none;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 2em;
    }
}

//skyline - popover
$popover-border-radius: 0rem !default;
$popover-border: 0 !default;

.popover.popover-body {
    padding: 0.25rem 0.75rem;
}

.popover.popover-content {
    border-radius: $popover-border-radius;
    border: $popover-border;
    max-height: 198px;
    min-width: 232px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);

    &.bs-popover-bottom-right > .arrow,
    &.bs-popover-top-right > .arrow {
        border-right-color: rgb(208, 208, 208);
        border-right-style: solid;
    }

    &.bs-popover-top-left > .arrow,
    &.bs-popover-bottom-left > .arrow {
        border-left-color: rgb(208, 208, 208);
        border-left-style: solid;
    }

    hr {
        margin-top: 0.2rem;
        margin-bottom: 0.1rem;
    }

    &.bs-popover {
        &-bottom-right {
            transform: translateX(3rem) translateX(10px) translateY(-1.5rem);

            > .arrow {
                left: -12px;
                margin: 0;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-right-width: 12px;
            }
        }

        &-bottom-left {
            transform: translateX(-3rem) translateX(-10px) translateY(-1.5rem);

            > .arrow {
                top: 0;
                right: -12px;
                margin: 0;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left-width: 12px;
            }
        }

        &-top-right {
            transform: translateX(3rem) translateX(10px) translateY(1.5rem);

            > .arrow {
                bottom: 0;
                left: -12px;
                margin: 0;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-right-width: 12px;
            }
        }

        &-top-left {
            transform: translateX(-3rem) translateX(-10px) translateY(1.5rem);

            > .arrow {
                bottom: 0;
                right: -12px;
                margin: 0;
                width: 0;
                height: 0;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left-width: 12px;
            }
        }
    }
}

popover-content.medium-level-of-details {
    .popover.popover-content.bs-popover {
        &-bottom-right {
            transform: translateX(3rem) translateX(65px) translateY(-1.5rem);
        }

        &-bottom-left {
            transform: translateX(-3rem) translateX(-65px) translateY(-1.5rem);
        }

        &-top-right {
            transform: translateX(3rem) translateX(65px) translateY(1.5rem);
        }

        &-top-left {
            transform: translateX(-3rem) translateX(-65px) translateY(1.5rem);
        }
    }
}

popover-content.wide-level-of-details {
    .popover.popover-content.bs-popover {
        &-bottom-right {
            transform: translateX(3rem) translateX(-15px) translateY(-1.5rem);
        }

        &-bottom-left {
            transform: translateX(-3rem) translateX(15px) translateY(-1.5rem);
        }

        &-top-right {
            transform: translateX(3rem) translateX(-15px) translateY(1.5rem);
        }

        &-top-left {
            transform: translateX(-3rem) translateX(15px) translateY(1.5rem);
        }
    }
}

.hover-pointer {
    cursor: pointer;
}

.hover-pointer.resize-column {
    cursor: col-resize;
}

.header-underline {
    text-decoration: underline;
}

.checkbox-section .mat-checkbox-layout {
    white-space: nowrap;
}

svg.ngx-charts:not(:root) {
    overflow: visible;
}

.sort-container {
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;

        span {
            text-align: center;
        }
    }

    .sort-icon {
        display: flex;
        align-self: center;
        height: 18px;

        .mat-icon {
            height: 18px;
            width: 18px;
            font-size: 18px;
        }
    }
}

app-monthly-change-raised header.legend-title {
    min-height: 0;
    padding-top: 0;
    max-width: 190px;
    min-width: 100px;
}

app-redzone,
app-bluezone,
app-weekly-planning-session-table {
    .end .mat-sort-header-container {
        justify-content: flex-end;
    }
}

app-table-total-complete .table-complete-container {
    position: relative;
    min-height: 500px;
}

app-mc-schedule .schedule-container {
    position: relative;
    min-height: 380px;
    width: 1260px;
}

app-mc-skyline .skyline-container {
    position: relative;
    min-height: 250px;
    width: 1260px;
}

app-mc-schedule {
    margin: 0 auto;
}

app-mc-skyline {
    margin: 0 auto;
}

app-table-total-complete {
    margin: 0 auto;
}

app-icicle-chart-main.longerlabel .chart-legend {
    margin-left: 20px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-bar {
        background-color: $sctcolorlight;
    }

    .mat-slide-toggle-thumb {
        background-color: $sctcolor;
    }
}

app-clearable-mat-datepicker {
    label {
        margin-bottom: 0;
    }
    table {
        margin: 0;
    }
}

.jodit-container-comments-type {
    position: relative;
    padding: 0px 25px 0px 25px;
    height: 200px;
    word-break: break-word;
    border: 1px solid black;
    padding-top: 90px;
    width: 182px;
}

.jodit-container-comments {
    width: 359px;

    > div {
        position: relative;
        padding: 25px 25px 0px 25px;
        min-height: 200px;
        word-break: break-word;
        border: 1px solid black;
        cursor: pointer;
    }

    &.description-error {
        border: 1px solid $medium-red;
    }

    &.description-disabled {
        pointer-events: none;
        border: 1px solid gray;
        background-color: rgb(235, 235, 228);
    }
}

.jodit-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.6);
}

g.line-chart > g:last-of-type > g:nth-child(n) g.line-series > path {
    stroke-width: 5;
    stroke-linecap: round;
}

app-milestone-dashboard .resize.resize__bottom {
    background-color: #d3d3d370;
}

.title {
    color: $sctcolordark;
    font-family: 'GothamNarrowBold', Arial, sans-serif;
    font-size: 26px;
}

.sub-title {
    color: $sctcolordark;
    font-family: 'GothamNarrowBold', Arial, sans-serif;
    font-size: 16px;
}

.mat-table-sticky-header {
    overflow: auto;
    height: calc(100vh - 220px);

    .mat-header-row {
        position: sticky;
        top: -2px;
        z-index: 2;
    }
}

// MEDIA QUEREIES

//subsystem - field change summary printout
@media print {
    .doNotPrint {
        display: none !important;
    }

    .title-container .title {
        color: #000 !important;
    }

    .main h4 {
        color: #464646 !important;
    }

    thead {
        display: table-header-group;
    }

    .background {
        background-color: white !important;
    }

    .sig-descr-container {
        color: #000;
        font-family: Arial, sans-serif;
        break-inside: avoid;
    }

    .mat-cell {
        color: #000;
        font-family: Arial, sans-serif;
    }

    td.mat-cell[class*='mat-column-'] {
        font-size: 12px;
    }

    main.main .signoff-container .sig-descr-container > div {
        font-size: 12px;
    }

    .fieldchange-container table tbody tr:nth-child(odd) td {
        background: none;
    }
}

//overwrite Next.css to not hide navbar & tables
@media (max-width: 1199.98px) {
    nav#site-specific-nav {
        display: block;
        width: 100%;
    }

    div table {
        margin-top: 0;
    }

    div table tr {
        th,
        td {
            display: table-cell;
        }
    }
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    app-multiple-items-selector .mat-chip-list-wrapper input.mat-input-element {
        margin: 2px;
    }
}

//IE fix for mat-chip in mat accordion Bug 1636922, 1656306
/* prettier-ignore */
@media screen and (min-width: 0\0) and (min-resolution: +72dpi), \0screen\,screen\9 {
    mat-accordion mat-expansion-panel.mat-expansion-panel .mat-expansion-panel-header {
        height: auto !important;
    }

    .mat-expansion-panel-header .mat-content {
        flex-wrap: wrap;

        > div {
            flex: 1 1 100%;
        }
    }
}

//IE fix for black background on bar charts - bug 1920959
/* prettier-ignore */
@media screen and (min-width: 0\0) and (min-resolution: +72dpi), \0screen\,screen\9 {
    ngx-charts-bar-vertical-2d svg rect.gridpanel {
        fill: white;
    }
}

@media (resolution: 144dpi) {
    body {
        zoom: (0.85);
    }
}

.print-header {
    .mat-accordion {
        .mat-expansion-panel {
            box-shadow: none !important;
            padding: 10px !important;
        }
    }
}
