$sctcolorlight: #00adee;
$sctcolor: #0066b2;
$sctcolordark: #0b2d71;
$sctcolorgreylight: #f7f7f7;
$sctcolorgreymid: #d5d5d5;
$sctcolorgreydark: #7a7a7a;

$linkcolor: #0066b2;

$light-red: #e21836;
$medium-red: #97002e;
$dark-red: #58001c;

$light-green: #b2cc34;
$medium-green: #769231;
$dark-green: #444b0d;


